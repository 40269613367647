import AsyncStorage from '@react-native-async-storage/async-storage';

const prefix_session = 'auth_';

export const AuthSession_stringStoreData = async (key,value) => {
    try {
      await AsyncStorage.setItem(prefix_session+key, JSON.stringify(value))
      console.log('success save at key '+key, value)

    } catch (e) {
      // saving error
      console.log('error save at key '+key, e)
    }
}

export const AuthSession_objectStoreData = async (key,value) => {
    try {
      const jsonValue = JSON.stringify(value)
      await AsyncStorage.setItem(prefix_session+key, jsonValue)
      console.log('success save at key '+key, value)
    } catch (e) {
      // saving error
      console.log('error save at key '+key, e)
    }
}



export const AuthSession_stringGetData = async (key) => {
    try {
      const value = await AsyncStorage.getItem(prefix_session+key)
      if(value !== null) {
        // value previously stored
        return value.replace('"','').replace('"','');
      }
    } catch(e) {
      // error reading value
      console.log('error get at key '+key, e)
    }
}


export const AuthSession_objectGetData = async (key) => {
    try {
      const jsonValue = await AsyncStorage.getItem(prefix_session+key)
      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch(e) {
      // error reading value
      console.log('error get at key '+key, e)
    }
}
  

export const AuthSession_RemoveAll = async () => {
  try {
      AsyncStorage.getAllKeys((err, keys) => {
          AsyncStorage.multiGet(keys, (error, stores) => {
              stores.map((result, i, store) => {
                  if((store[i][0]).includes(prefix_session)){
                      AsyncStorage.removeItem(store[i][0])
                      // console.log(store[i][0]);
                  }
                  
                  // console.log({ [store[i][0]]: store[i][1] });
                  
                  return true;
              });
          });
      });

  } catch (e) {
      // error reading value
      console.log("error getData session " + prefix_session);
      return "";
  }
}



export const removeAllSession = async () => {
  try {
      AsyncStorage.getAllKeys((err, keys) => {
          AsyncStorage.multiGet(keys, (error, stores) => {
              stores.map((result, i, store) => {
                      AsyncStorage.removeItem(store[i][0])
                  
                  // console.log({ [store[i][0]]: store[i][1] });
                  
                  return true;
              });
          });
      });

  } catch (e) {
      // error reading value
      console.log("error getData session " + prefix_session);
      return "";
  }
}
