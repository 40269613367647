import React, { useEffect } from "react";
import { AuthSession_RemoveAll, AuthSession_stringGetData } from "../helper/session/AuthSession";
import { useNavigation } from "@react-navigation/native";

const AuthContext = React.createContext(null);

// This hook can be used to access the user info.
export function useAuth() {
  return React.useContext(AuthContext);
}


export function AuthProvider(props) {

  const [user, setAuth] = React.useState(null);

  useEffect( () => {
    getSession();
  }, [])

  const getSession = async () => {

    const token = await AuthSession_stringGetData('token');
    console.log("get session",token)
    if ( token ) {
      setAuth(token)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        signIn: () => setAuth({}),
        signOut: () => {
            AuthSession_RemoveAll();
            setAuth(null);
        },
        user,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}