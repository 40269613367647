import { StyleSheet, Text, View, ImageBackground } from 'react-native'
import React from 'react'
import axios from 'axios';
import { TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';

export default function ModalAbsenPhoto({route}) {
    const {photo} = route.params
    const navigation = useNavigation();
  React.useEffect(() => {
    console.log('params', photo && photo.uri)
    return () => {
        console.log('navi', navigation)
    }
  }, [])
  

  const handleNext = () => {
    navigation.navigate('Absen', {
        photo: photo
    })
  }

  return (
      <ImageBackground
        source={{uri: photo && photo.uri}}
        style={{
          flex: 1,
          // transform: [{scale: 1}],
          width: '100%',
          // height: '100%',
        }}
      >
        {/* <View style={styles.buttonContainer}>
            <TouchableOpacity style={styles.button} onPress={handleNext}>
                <Text style={styles.text}>Absen</Text>
            </TouchableOpacity>
        </View> */}
      </ImageBackground>
  )
}

const styles = StyleSheet.create({
    buttonContainer: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'transparent',
        margin: 34,
        justifyContent: 'space-between',
      },
      button: {
        flex: 1,
        alignSelf: 'flex-end',
        alignItems: 'center',
      },
      text: {
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
      },
})