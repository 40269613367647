import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { ActivityIndicator, MD2Colors } from 'react-native-paper';
import { primaryColor } from '../Constant';
export default function LoadingScreen() {
    
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <ActivityIndicator animating={true} color={primaryColor} />
      <Text>Loading</Text>
    </View>
  )
}

const styles = StyleSheet.create({})